<template>
  <div>
    <CommentsFormStep :progress-value="progressValue" />
  </div>
</template>

<script>
import CommentsFormStep from '~/components/form/steps/comments/CommentsFormStep'
import FormPage from '~/components/form/FormPage'

export default {
  name: 'CommentsFormPage',

  components: {
    CommentsFormStep,
  },

  extends: FormPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Bijzonderheden',
      path: '/offertes-aanvragen/opmerkingen',
      progressValue: 80,
    }
  },
}
</script>
