<template>
  <div>
    <PriceRangeFormStep
      v-if="serviceIsAppraisal() || serviceIsSales()"
      :progress-value="progressValue"
    />
    <RedirectFormPart v-else />
  </div>
</template>

<script>
import RedirectFormPart from 'chimera/all/components/form/parts/RedirectFormPart'
import PriceRangeFormStep from '~/components/form/steps/priceRange/PriceRangeFormStep'
import FormPage from '~/components/form/FormPage'

export default {
  name: 'PriceRangePage',

  components: {
    PriceRangeFormStep,
    RedirectFormPart,
  },

  extends: FormPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Prijsklasse woning',
      path: '/offertes-aanvragen/prijsklasse',
      progressValue: 60,
    }
  },
}
</script>
