<template>
  <div />
</template>

<script>
export default {
  name: 'RedirectFormPart',

  props: {
    path: {
      type: String,
      default: '/',
    },
  },

  /**
   * Redirect to path
   */
  mounted() {
    this.$router.push({ path: this.path })
  },
}
</script>
