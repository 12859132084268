<template>
  <section>
    <t-form ref="form" @submit="onSubmit" @submit.prevent>
      <PriceRangeFormPart
        :is-optional="true"
        @result="onResult"
        @change="onChange"
      />
      <FormStepSubmitButton id="submitPriceRange" :text="$t('button')" />
    </t-form>
  </section>
</template>

<script>
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import PriceRangeFormPart from 'chimera/realEstateAgency/components/form/parts/priceRange/PriceRangeFormPart'
import CommentsFormPage from '~/pages/offertes-aanvragen/opmerkingen'

export default {
  name: 'PriceRangeFormStep',

  components: {
    PriceRangeFormPart,
  },

  extends: AbstractFormStep,

  methods: {
    /**
     * Transition
     */
    transition() {
      this.routeTo(CommentsFormPage)
    },
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "button": "Volgende"
  },
  "nl-BE": {
    "button": "U bent er bijna"
  }
}
</i18n>
